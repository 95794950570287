<div class="logo">
  <img src="assets/logos/logo-places-spaces.svg" routerLink="/home" alt="logo-places-spaces.svg">
</div>

<div class="filler"></div>

<ng-container *sciBreakpoint="'tablet-'; else: desktopNav">
  <div class="sidenav-toggle" [class.open]="sidenavOpen">
    <button mat-flat-button (click)="sidenavOpenChange.emit(sidenavOpen = !sidenavOpen)">
      <sci-menu-icon [alternateIcon]="sidenavOpen"></sci-menu-icon>
    </button>
  </div>
</ng-container>

<ng-template #desktopNav>

    <button  (mouseenter)="exhibitTrigger.closeMenu()" mat-button class="nav-item about mat-display-3" routerLink="/about" routerLinkActive="active">
      ABOUT
    </button>

    <div mat-button class="nav-item exhibit mat-display-3"
      (mouseenter)="mouseEvents($event, 0)"
      (mouseleave)="mouseEvents($event, 0)"
      [ngClass]="{'active': (exhibitTrigger.menuClosed | async) || exhibitTrigger.menuOpen || maps.isActive || macroscopes.isActive || books.isActive || cfm.isActive || venues.isActive || hosting.isActive }" [matMenuTriggerFor]="exhibit"
      #exhibitTrigger="matMenuTrigger">EXHIBIT
      <mat-icon>expand_more</mat-icon>
      <mat-menu class="sci-header-menu" #exhibit="matMenu" [hasBackdrop]="false" xPosition="before">
        <div (mouseenter)="mouseEvents($event, 0)" (mouseleave)="mouseEvents($event, 0)">
          <button mat-button class="mat-display-3" routerLink="/maps" routerLinkActive="active" #maps="routerLinkActive">MAPS</button>
          <button mat-button class="mat-display-3" routerLink="/macroscopes" routerLinkActive="active" #macroscopes="routerLinkActive">MACROSCOPES</button>
          <button mat-button class="mat-display-3" routerLink="/books" routerLinkActive="active" #books="routerLinkActive">BOOKS</button>
          <button mat-button class="mat-display-3" routerLink="/call-for-macroscopes" routerLinkActive="active" #cfm="routerLinkActive">CALL FOR SUBMISSIONS</button>
          <button mat-button class="mat-display-3" routerLink="/venues" routerLinkActive="active" #venues="routerLinkActive">VENUES</button>
          <button mat-button class="mat-display-3" routerLink="/hosting" routerLinkActive="active" #hosting="routerLinkActive">HOSTING</button>
        </div>
      </mat-menu>
    </div>



    <div mat-button class="nav-item explore mat-display-3"
      (mouseenter)="mouseEvents($event, 2)"
      (mouseleave)="mouseEvents($event, 2)"
      [class.active]="(exploreTrigger.menuClosed | async) || exploreTrigger.menuOpen" [matMenuTriggerFor]="explore"
      #exploreTrigger="matMenuTrigger">EXPLORE
      <mat-icon>expand_more</mat-icon>
      <mat-menu class="sci-header-menu" #explore="matMenu" [hasBackdrop]="false" xPosition="before">
        <div (mouseenter)="mouseEvents($event, 2)" (mouseleave)="mouseEvents($event, 2)">
          <button mat-button class="mat-display-3" routerLink="/learning-center" routerLinkActive="active">
            LEARNING CENTER
          </button>
        </div>
      </mat-menu>
    </div>



    <div mat-button class="nav-item contact mat-display-3"
    (mouseenter)="mouseEvents($event, 1)" (mouseleave)="mouseEvents($event, 1)"
    [class.active]="(contactTrigger.menuClosed | async) || contactTrigger.menuOpen" [matMenuTriggerFor]="contact"
    #contactTrigger="matMenuTrigger">
      CONTACT US
      <mat-menu class="sci-header-menu no-padding" #contact="matMenu" [hasBackdrop]="false">
        <div (mouseenter)="mouseEvents($event, 1)" (mouseleave)="mouseEvents($event, 1)">
          <sci-contact class="contact"></sci-contact>
        </div>
      </mat-menu>
  </div>
  <div (mouseenter)="contactTrigger.closeMenu()" class="search"></div>


</ng-template>
